import {NavLink, useNavigate, useParams} from "react-router-dom";
import env from '../../../env.json';
// import gridimage from "../../images/demo-diamond-grid.jpg";
import {Image} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {diamondReducerAction} from "../../../redux/reducers/diamondReducer";
import {Select} from "antd";
import {RootState} from "../../../redux/store";

export default function Compare() {
    // console.log(comparedData)
    const dynamicData = [];
    const {productID} = useParams();
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let datavalue;

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>
                {" "}
                <Image
                    src={` ${diamond.image_file ? diamond.image_file : env.wp_media_path}`}
                    className="full-image"
                    alt="grid-img"
                    height="250px"
                />

            </td>

        </>
    )) : false;

    dynamicData.push(
        <tr>
            <td>Product</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            {" "}
            <td>
                <NavLink
                    to={`/diamond-show/${diamond.diamond_id}/${productID}`}
                    className="choose-link"
                >
                    Diamond Details
                </NavLink>

            </td>

        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>View Details</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>Rs {diamond.total_sales_price_in_currency}</td>
        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Price</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            {" "}
            <td>
                <NavLink
                    to={`/diamond-show/${diamond.diamond_id}/${productID}`}
                    className="choose-btn"
                >
                    Choose This Diamond
                </NavLink>

            </td>

        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Select Diamond</td>
            {datavalue}
        </tr>
    );

    if (productID == "independentDiamond") {
        datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
            <>
                <td></td>
                <td>
                    <Select
                        defaultValue="Add To"
                        style={{
                            width: 120,
                        }}
                        onChange={(e) => {
                            switch (e) {
                                case "Ring":
                                    window.location.replace(`${env.wphost}/product-category/solitaire-jewellery/create-your-engagement-ring?diamondId=${diamond.diamond_id}`);
                                    break;
                                case "Pendant":
                                    window.location.replace(`${env.wphost}/product-category/solitaire-jewellery/create-your-pendant?diamondId=${diamond.diamond_id}`);
                                    break;
                                case "Cart":
                                    window.location.replace(`${env.wphost}/customized-complete-product?diamondId=${diamond.diamond_id}`);
                                    break;
                            }
                        }}
                        options={[
                            {
                                value: 'Ring',
                                label: 'Ring',
                            },
                            {
                                value: 'Pendant',
                                label: 'Pendant',
                            },
                            {
                                value: 'Cart',
                                label: 'Cart',
                            }
                        ]}
                    />
                </td>

            </>
        )) : false;
        dynamicData.push(
            <tr>
                <td>Add to</td>
                {datavalue}
            </tr>
        );
    }


    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>{diamond.shape}</td>

        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Shape</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>{diamond.size}</td>

        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Carat Weight</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>{diamond.color}</td>

        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Color</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>{diamond.clarity}</td>

        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Clarity</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>{diamond.cut}</td>

        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Cut</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>{diamond.polish}</td>

        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Polish</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>{diamond.symmetry}</td>

        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Symmetry</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>{diamond.fluor_intensity}</td>

        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Fluorescence</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>Natural</td>

        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Certificate</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>{diamond.table_percent}</td>

        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Table %</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>{diamond.depth_percent}</td>

        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Total Depth %</td>
            {datavalue}
        </tr>
    );

    datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
        <>
            <td></td>
            <td>
                {diamond.meas_length} x {diamond.meas_width} x {diamond.meas_depth} mm
            </td>
        </>
    )) : false;
    dynamicData.push(
        <tr>
            <td>Measurements</td>
            {datavalue}
        </tr>
    );

    /* datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
      <>
        <td></td>
        <td>1.00</td>

      </>
    )) : false;
    dynamicData.push(
      <tr>
        <td>Lenght/Widht Ratio</td>
        {datavalue}
      </tr>
    ); */

    /* datavalue = state.diamondReducer.comparisonDiamond ? state.diamondReducer.comparisonDiamond.map((diamond) => (
      <>
        <td></td>
        <td>None</td>

      </>
    )) : false;
    dynamicData.push(
      <tr>
        <td>Shade/Milky</td>
        {datavalue}
      </tr>
    ); */

    return (
        <>
            <table className="table table-striped table-bordered ext-table text-center compare-table">
                <thead>
                <tr>
                    <th>Action</th>
                    {state.diamondReducer.comparisonDiamond && state.diamondReducer.comparisonDiamond[0] && (<>
                        <td></td>
                        <th>
                            <button onClick={e => {
                                dispatch(diamondReducerAction.REMOVE_IN_COMPARISON(state.diamondReducer.comparisonDiamond[0]))
                            }} className="remove-bin">Remove
                            </button>
                        </th>
                    </>)}
                    {state.diamondReducer.comparisonDiamond && state.diamondReducer.comparisonDiamond[1] && (<>
                        <td></td>
                        <th>
                            <button onClick={e => {
                                dispatch(diamondReducerAction.REMOVE_IN_COMPARISON(state.diamondReducer.comparisonDiamond[1]))
                            }} className="remove-bin">Remove
                            </button>
                        </th>
                    </>)}
                    {state.diamondReducer.comparisonDiamond && state.diamondReducer.comparisonDiamond[2] && (<>
                        <td></td>
                        <th>
                            <button onClick={e => {
                                dispatch(diamondReducerAction.REMOVE_IN_COMPARISON(state.diamondReducer.comparisonDiamond[2]))
                            }} className="remove-bin">Remove
                            </button>
                        </th>
                    </>)}
                    {state.diamondReducer.comparisonDiamond && state.diamondReducer.comparisonDiamond[3] && (<>
                        <td></td>
                        <th>
                            <button onClick={e => {
                                dispatch(diamondReducerAction.REMOVE_IN_COMPARISON(state.diamondReducer.comparisonDiamond[3]))
                            }} className="remove-bin">Remove
                            </button>
                        </th>
                    </>)}
                    {state.diamondReducer.comparisonDiamond && state.diamondReducer.comparisonDiamond[4] && (<>
                        <td></td>
                        <th>
                            <button onClick={e => {
                                dispatch(diamondReducerAction.REMOVE_IN_COMPARISON(state.diamondReducer.comparisonDiamond[4]))
                            }} className="remove-bin">Remove
                            </button>
                        </th>
                    </>)}
                </tr>
                </thead>
                <tbody>{dynamicData}</tbody>
            </table>
        </>
    );
}
