import axios from "axios";
import env from '../../env.json';
import Cookies from 'universal-cookie';
import {endUserFilterInitialStateDto, filterMainDataStateDto} from "../../redux/reducers/endUserFilter";
import {callRepnetAPI} from "../../utils/general";

function cut(c: any): string[] {
    let abc: string[] = [], s;
    if (c.length) {
        s = 100 / (c.length - 1);
    } else {
        s = 100;
    }
    for (let i = 0; i < c.length; i++) {
        abc[parseInt((i * s).toString())] = c[i].name;
    }

    return abc
}

function carat(c: any) {
    let abc: number[] = [], k, signf = 10;

    k = (parseInt(c.max) - parseInt(c.min)) / signf;

    for (let i = 0; i < signf + 1; i++) {
        // @ts-ignore
        abc[parseInt(i * (100 / signf)).toString()] = (((i * signf) * k) / (100 / signf)).toFixed(2);
    }

    return abc
}

function clarity(c: any) {
    let abc: { [key: string]: any } = {};
    let s: number;

    if (c.length) {
        s = 100 / (c.length - 1);  // Calculate the interval
    } else {
        s = 100;  // Default value when there's no length
    }

    for (let i: number = 0; i < c.length; i++) {
        // Ensure i * s is parsed as a string index
        abc[parseInt((i * s).toString())] = c[i].name;
    }

    return abc;
}

function price(c: any) {
    let abc: any = [], k, signf: any = 10;

    k = (parseInt(c.max) - parseInt(c.min)) / signf;

    for (let i = 0; i < signf + 1; i++) {
        abc[parseInt((i * (100 / signf)).toString())] = (((i * signf) * k) / (100 / signf)).toFixed(2);
    }

    return abc
}

function changeFilterDataToRight(filterresponse: any) {
    let filterFiltered = filterresponse;

    return filterFiltered;
}

function createFirstFilterData(mainfilterdata: any) {
    let flt = mainfilterdata;
    flt.shape = flt.shape.map((i: any) => i.name)
    return flt
}

function getIndexFromString(strings: any, newstring: string) {
    for (let i = 0; i < strings.length; i++) {
        if (strings[i].name = newstring) {
            return i;
        }
    }
}

// type endUserFilterDto={
//     carat:{min:number,max:number} | null;
//     certificate:string[] | null;
//     clarity:{min:number,max:number} | null;
//     cut:{min:number,max:number}  | null;
//     fancy_color:string[] | null;
//     fancy_color_intensity:string[] | null;
//     fancy_diamond_color:string[] | null;
//     fluorescence:string[] | null;
//     length_width_ratio:string[] | null;
//     polish:string[] | null;
//     price:{min:number,max:number}  | null;
//     shademilky:string[] | null;
//     shape:string[] | null;
//     symmetry:string[] | null;
//     sort_by:string | null,
//     page_number:number | null,
//     table:{min:number,max:number}  | null;
//     total_depth:{min:number,max:number}  | null;
// }

function changeFilterToRepnet(endFilters: endUserFilterInitialStateDto, filterMainData: filterMainDataStateDto, colorTab: any): any {
    // console.log(endFilters.shape, endFilters.fancy_diamond_color);
    let repnetFilter: any = {};
    // if (endFilters.search) {
    //   if(endFilters.searchType =="SKU"){
    //     repnetFilter.diamond_id = endFilters.search;
    //   }
    //   else if(endFilters.searchType =="Certificate"){
    //     repnetFilter.search_type = endFilters.search;
    //   }
    //    }
    if (endFilters.shape) {
        if (endFilters.shape.length > 0) repnetFilter.shapes = endFilters.shape; else repnetFilter.shapes = filterMainData.shape.map((i: any) => i.name)
    }
    if (endFilters.carat && endFilters.carat.min) repnetFilter.size_from = endFilters.carat.min; else repnetFilter.size_from = filterMainData.carat.min;
    if (endFilters.carat && endFilters.carat.max) repnetFilter.size_to = endFilters.carat.max; else repnetFilter.size_to = filterMainData.carat.max;

    if (colorTab == "color") {
        if (endFilters.fancy_color) {
            if (endFilters.fancy_color.length) repnetFilter.color_from = endFilters.fancy_color[0]; else repnetFilter.color_from = filterMainData.fancy_color.map((i: any) => i.Name)[0];
            if (endFilters.fancy_color.length) repnetFilter.color_to = endFilters.fancy_color[endFilters.fancy_color.length - 1]; else repnetFilter.color_to = filterMainData.fancy_color.map((i: any) => i.Name)[filterMainData.fancy_color.length - 1];
        } else {

        }
    } else {
        if (endFilters.fancy_diamond_color && endFilters.fancy_color_intensity) {
            if (endFilters.fancy_diamond_color.length > 0) repnetFilter.fancy_colors = endFilters.fancy_diamond_color; else repnetFilter.fancy_colors = filterMainData.fancy_diamond_color.map((i: any) => i.Name);
            if (endFilters.fancy_color_intensity.length) repnetFilter.fancy_color_intensity_from = endFilters.fancy_color_intensity[0]; else repnetFilter.fancy_color_intensity_from = filterMainData.fancy_color_intensity.map((i: any) => i.Name)[0];
            if (endFilters.fancy_color_intensity.length) repnetFilter.fancy_color_intensity_to = endFilters.fancy_color_intensity[endFilters.fancy_color_intensity.length - 1]; else repnetFilter.fancy_color_intensity_to = filterMainData.fancy_color_intensity.map((i: any) => i.Name)[filterMainData.fancy_color_intensity.length - 1];

        }
    }

    if (endFilters.clarity) repnetFilter.clarity_from = endFilters.clarity.min; else repnetFilter.clarity_from = filterMainData.clarity[0].name;
    if (endFilters.clarity) repnetFilter.clarity_to = endFilters.clarity.max; else repnetFilter.clarity_to = filterMainData.clarity[filterMainData.clarity.length - 1].name;
    if (endFilters.cut) repnetFilter.cut_from = endFilters.cut.min; else repnetFilter.cut_from = filterMainData.cut[0].name;
    if (endFilters.cut) repnetFilter.cut_to = endFilters.cut.max; else repnetFilter.cut_to = filterMainData.cut[filterMainData.cut.length - 1].name;
    if (endFilters.polish && endFilters.polish[0]) repnetFilter.polish_from = endFilters.polish[0]; else {
        if (filterMainData.polish && filterMainData.polish[0]) repnetFilter.polish_from = filterMainData.polish[0].name;
    }
    if (endFilters.polish) repnetFilter.polish_to = endFilters.polish[endFilters.polish.length - 1]; else {
        if (filterMainData.polish && filterMainData.polish[0])
            repnetFilter.polish_to = filterMainData.polish[filterMainData.polish.length - 1].name;
    }
    if (endFilters.symmetry) repnetFilter.symmetry_from = endFilters.symmetry[0]; else repnetFilter.symmetry_from = filterMainData.symmetry.min;
    if (endFilters.symmetry) repnetFilter.symmetry_to = endFilters.symmetry[endFilters.symmetry.length - 1]; else repnetFilter.symmetry_to = filterMainData.symmetry.max;
    if (endFilters.fluorescence && endFilters.fluorescence.length > 0) repnetFilter.fluorescence_intensities = endFilters.fluorescence; else repnetFilter.fluorescence_intensities = filterMainData.fluorescence.map((i: any) => i.name);
    if (endFilters.price && endFilters.price.min) repnetFilter.price_total_from = endFilters.price.min; else repnetFilter.price_total_from = filterMainData.price.min;
    if (endFilters.price && endFilters.price.max) repnetFilter.price_total_to = endFilters.price.max; else repnetFilter.price_total_to = filterMainData.price.max;
    if (endFilters.certificate && endFilters.certificate.length > 0) repnetFilter.labs = endFilters.certificate.map((i: any) => i.name); else repnetFilter.labs = filterMainData.certificate.map((i: any) => i.name);
    if (endFilters.total_depth) repnetFilter.depth_percent_from = endFilters.total_depth.min; else repnetFilter.depth_percent_from = filterMainData.total_depth.min;
    if (endFilters.total_depth) repnetFilter.depth_percent_to = endFilters.total_depth.max; else repnetFilter.depth_percent_to = filterMainData.total_depth.max;
    if (endFilters.table) repnetFilter.table_percent_from = endFilters.table.min; else repnetFilter.table_percent_from = filterMainData.table.min;
    if (endFilters.table) repnetFilter.table_percent_to = endFilters.table.max; else repnetFilter.table_percent_to = filterMainData.table.max;
    if (endFilters.table) repnetFilter.table_percent_to = endFilters.table.max; else repnetFilter.table_percent_to = filterMainData.table.max;
    repnetFilter.page_number = endFilters.page_number;
    repnetFilter.page_size = 40;
    repnetFilter.sort_by = endFilters.sort_by;
    repnetFilter.sort_direction = "Asc";
    return repnetFilter;
}

function findEarring(access_token: string, firstDiamondList: any, finalFilter: any, sliceValue: number = 40) {
    return new Promise(async (resolve, reject) => {
        const finalEarrings: any = []
        // finalFilter.page_number = finalFilter.page_number;
        finalFilter.page_size = 1000;
        const data: any = await callRepnetAPI(env.newRepNetURL, access_token,  finalFilter)

        if (data) {
            const d = data.diamonds;
            if (d) {
                d.splice(0, sliceValue)

                for (let i = 0; i < firstDiamondList.length; i++) {
                    let FE: any = {}
                    FE.one = firstDiamondList[i];
                    for (let j = 0; j < d.length; j++) {
                        if (firstDiamondList[i].shape != d[j].shape) continue;
                        if (!(firstDiamondList[i].size - 0.4 < d[j].size && firstDiamondList[i].size + 0.4 > d[j].size)) continue;
                        if (firstDiamondList[i].color != d[j].color) continue;
                        if (firstDiamondList[i].clarity != d[j].clarity) continue;
                        if (firstDiamondList[i].cut != d[j].cut) continue;
                        if (firstDiamondList[i].polish != d[j].polish) continue;
                        if (firstDiamondList[i].symmetry != d[j].symmetry) continue;
                        if (firstDiamondList[i].lab != d[j].lab) continue;
                        if (firstDiamondList[i].fluor_intensity != d[j].fluor_intensity) continue;
                        FE.two = d[j];
                    }
                    if (FE.two) finalEarrings.push(FE)
                }

                resolve(finalEarrings)
            } else {
                reject('no data coming')
            }
        }
    });

}

function buttonGroupSlider(mainFilter: any, endFilter: any, item: any) {

    let efluorescence: any = [], FMFancy = mainFilter;
    if (endFilter.length == 0) {
        efluorescence.push(item)
    } else {
        const iIndex = mainFilter.indexOf(item);
        const minIndex = mainFilter.indexOf(endFilter[0])
        const maxIndex = mainFilter.indexOf(endFilter[endFilter.length - 1])
        if (maxIndex < iIndex) {
            for (let i = minIndex; i <= iIndex; i++) {
                efluorescence.push(FMFancy[i])
            }
        } else if (iIndex < minIndex) {
            for (let i = iIndex; i <= maxIndex; i++) {
                efluorescence.push(FMFancy[i])
            }
        } else {
            const minMinus = iIndex - minIndex;
            const maxMinus = maxIndex - iIndex;
            if (iIndex == minIndex) {
                efluorescence.push(item)
            } else if (iIndex == maxIndex) {
                efluorescence.push(item)
            } else if (minMinus >= maxMinus) {
                for (let i = minIndex; i <= iIndex; i++) {
                    efluorescence.push(FMFancy[i])
                }
            } else if (minMinus < maxMinus) {
                for (let i = iIndex; i <= maxIndex; i++) {
                    efluorescence.push(FMFancy[i])
                }
            } else {
                efluorescence = endFilter

            }
        }
    }
    return efluorescence
}

function setQueryString(endUserFilter: any) {
    let abc: any = {};
    abc.shape = endUserFilter.shape;
    abc.carat = endUserFilter.carat;
    abc.fancy_color = endUserFilter.fancy_color;
    abc.dominantColors = endUserFilter.dominantColors;
    abc.colorIntensity = endUserFilter.colorIntensity;
    abc.clarity = endUserFilter.clarity;
    abc.cut = endUserFilter.cut;
    abc.polish = endUserFilter.polish;
    abc.symmetry = endUserFilter.symmetry;
    abc.fluorescence = endUserFilter.fluorescence;
    abc.certificate = endUserFilter.certificate.map((i: any) => i.name);
    abc.price.min = endUserFilter.price.min;
    abc.price.max = endUserFilter.price.max;
    abc.table = endUserFilter.table;
    abc.total_depth = endUserFilter.total_depth;
    abc.ratio = endUserFilter.ratio;
    abc.totaldepth = endUserFilter.totaldepth;
    abc.measurements = endUserFilter.measurements;
    abc.LWRatio = endUserFilter.LWRatio;
    abc.shademilky = endUserFilter.shademilky;
    abc.page_number = endUserFilter.page_number;
    abc.page_size = endUserFilter.page_size;
    abc.sort_by = endUserFilter.sort_bye;
    abc.sort_direction = endUserFilter.sort_directionc;
    abc.search = endUserFilter.search;

    return abc;
}

function customDesignDiamondFilter(customDesign: any, sort: any) {
    //  "body": { "shapes": ["Round", "Princess"], "size_from": "0.01", "size_to": "2", "color_from": "D", "color_to": "M", "clarity_from": "IF", "clarity_to": "VS1", "cut_from": "Excellent", "cut_to": "Good", "polish_from": "Good", "polish_to": "Good", "symmetry_from": "Very Good", "symmetry_to": "Very Good", "fluorescence_intensities": ["None", "Very Slight"],:
    let abc: any = {};
    const cr = customDesign.colorRange;
    abc.shapes = [customDesign.diamondShape];
    if (customDesign.diamondShape == "round") {
        customDesign.cut_from = "Excellent"
        customDesign.cut_to = "Excellent"
        customDesign.polish_from = "Excellent"
        customDesign.polish_to = "Very Good"
        customDesign.symmetry_from = "Excellent"
        customDesign.symmetry_to = "Very Good"
        customDesign.fluorescence_intensities = ["None"]
        customDesign.labs = ["GIA"]
    } else {
        customDesign.polish_from = "Excellent"
        customDesign.polish_to = "Very Good"
        customDesign.symmetry_from = "Excellent"
        customDesign.symmetry_to = "Very Good"
        customDesign.fluorescence_intensities = ["None"]
        customDesign.labs = ["GIA"]
    }
    if (customDesign.caratSize < 1) {
        abc.size_from = customDesign.caratSize + 0.02;
    } else if (customDesign.caratSize < 2) {
        abc.size_from = customDesign.caratSize + 0.05;
    } else {
        abc.size_from = customDesign.caratSize + 1;
    }
    abc.size_to = customDesign.caratSize;
    if (cr[0]) {
        cr.sort((a: any, b: any) => a.index - b.index);
        abc.color_from = cr[0].name.substring(0, cr[0].name.indexOf("-"));
        abc.color_to = cr[cr.length - 1].name.substring(cr[cr.length - 1].name.indexOf("-") + 1, cr[cr.length - 1].name.length);

    }
    if (customDesign.diamondQuality == "FL-IF") {
        abc.clarity_from = customDesign.diamondQuality.substring(0, customDesign.diamondQuality.indexOf("-"));
        abc.clarity_to = customDesign.diamondQuality.substring(customDesign.diamondQuality.indexOf("-") + 1, customDesign.diamondQuality.length);
    } else {
        abc.clarity_from = customDesign.diamondQuality;
        abc.clarity_to = customDesign.diamondQuality;
    }
    abc.page_number = 1;
    abc.page_size = 5;
    abc.sort_by = "price";
    abc.sort_direction = sort;
    return abc
}

function customDesignShappireFilter(customDesign: any, sort: any) {
    //  "body": { "shapes": ["Round", "Princess"], "size_from": "0.01", "size_to": "2", "color_from": "D", "color_to": "M", "clarity_from": "IF", "clarity_to": "VS1", "cut_from": "Excellent", "cut_to": "Good", "polish_from": "Good", "polish_to": "Good", "symmetry_from": "Very Good", "symmetry_to": "Very Good", "fluorescence_intensities": ["None", "Very Slight"],:
    let abc: any = {};
    const cr = customDesign.shappireColor;
    abc.shapes = [customDesign.shappireShape];
    if (customDesign.shappireCarat) {
        abc.size_from = customDesign.shappireCarat.index.substring(0, customDesign.shappireCarat.index.indexOf("-"));
        abc.size_to = customDesign.shappireCarat.index.substring(customDesign.shappireCarat.index.indexOf("-") + 1, customDesign.shappireCarat.index.length);
    }
    if (cr[0]) {
        abc.color_from = customDesign.shappireColor;
        abc.color_to = customDesign.shappireColor;

    }
    abc.clarity_from = customDesign.shappireQuality;
    abc.clarity_to = customDesign.shappireQuality;
    abc.page_number = 1;
    abc.page_size = 5;
    abc.sort_by = "price";
    abc.sort_direction = sort;
    return abc
}

function wishlistAPI(url: any, data: any, cb?: any) {
    const cookies = new Cookies();
    // cookies.set("wpuserid","Npc2JGvDWM%2FjoZ5JWVtvZg%3D%3D")
    const options = {
        method: 'POST',
        url: url,
        data: {
            ...data,
            cookieData: cookies.get('wpuserid')
        },
    };
    // axios.defaults.withCredentials = true
    axios(options).then((res) => {
        const repRes = res.data.response;
        cb(res)
    })
        .catch(error => {
        });
}

export default {
    cut: cut,
    carat: carat,
    clarity: clarity,
    price: price,
    changeFilterDataToRight: changeFilterDataToRight,
    changeFilterToRepnet: changeFilterToRepnet,
    createFirstFilterData: createFirstFilterData,
    getIndexFromString: getIndexFromString,
    findEarring: findEarring,
    buttonGroupSlider: buttonGroupSlider,
    setQueryString: setQueryString,
    customDesignDiamondFilter: customDesignDiamondFilter,
    customDesignShappireFilter: customDesignShappireFilter,
    wishlistAPI: wishlistAPI
}