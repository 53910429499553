import "./diamond-finder.css";
import Filters from "../components/filter/filters";

import EarringListing from '../components/listings/earringsListing'
import env from '../env.json';
import {useDispatch, useSelector} from "react-redux";
import {diamondReducerAction} from "../redux/reducers/diamondReducer";
import indFunction from '../components/common/IndFunctions';
import {useEffect} from "react";
import {useLocation} from 'react-router-dom';
import {RootState} from "../redux/store";
import {callRepnetAPI, callWordpressAPI} from "../utils/general";

export default function EarringFinder() {

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const location = useLocation();

    async function refreshData() {
        if (state.diamondReducer.access_token && state.diamondReducer.access_token != "") {
            if (state.endUserFilter.search) {
                const data: any = await callRepnetAPI(env.newRepNetSingleURL, state.diamondReducer.access_token, {
                    request: {
                        header: {
                            // username: env.username,
                            // password: env.password,
                        },
                        body: {
                            diamond_id: parseInt(state.endUserFilter.search)
                        },
                    }
                })

                if (data) {
                    let a = state.endUserFilter


                    a.shape = [data.response.body.diamond.shape];
                    a.fancy_color = [data.response.body.diamond.color];
                    a.clarity.min = data.response.body.diamond.clarity;
                    a.clarity.max = data.response.body.diamond.clarity;
                    a.cut.min = data.response.body.diamond.cut;
                    a.cut.max = data.response.body.diamond.cut;
                    a.polish = [data.response.body.diamond.polish];
                    a.symmetry = [data.response.body.diamond.symmetry];
                    a.labs = [data.response.body.diamond.lab];
                    a.fluorescence = [data.response.body.diamond.fluor_intensity];

                    if (data.response.body.diamond.fluor_intensity) a.fluorescence_intensities = data.response.body.diamond.fluor_intensity
                    const mainFilter = indFunction.changeFilterToRepnet(a, state.endUserFilter.filterMainData, state.diamondReducer.colorTab);
                    // console.log('checking earring now', data.response.body.diamond, mainFilter,a)
                    indFunction.findEarring(state.diamondReducer.access_token, [data.response.body.diamond], mainFilter, 1).then(finalEarring => {
                        dispatch(diamondReducerAction.ADD_DIAMONDS_DATA({diamonds: finalEarring, totalDiamonds: 1}))
                    })
                }


            } else {
                const mainFilter = indFunction.changeFilterToRepnet(state.endUserFilter, state.endUserFilter.filterMainData, state.diamondReducer.colorTab)
                mainFilter.page_size = 60;
                const data: any = await callRepnetAPI(env.newRepNetURL, state.diamondReducer.access_token, mainFilter)
                if (data) {
                    if (data.search_results) {
                        indFunction.findEarring(state.diamondReducer.access_token, data.diamonds, mainFilter).then(finalEarring => {
                            console.log(finalEarring, '-------------------------')
                            dispatch(diamondReducerAction.ADD_DIAMONDS_DATA({
                                diamonds: finalEarring,
                                totalDiamonds: data.search_results.total_diamonds_found,
                                returnedDiamonds: data.search_results.diamonds_returned
                            }))
                        })
                    }
                }
            }
        }
    }

    useEffect(() => {
        let loc = location.pathname;
        loc = loc.substring(1, loc.length)
        loc = loc.substring(0, loc.indexOf('/'));
        dispatch(diamondReducerAction.ADDJOURNEY(loc))
        refreshData()
    }, [state.endUserFilter, state.endUserFilter.filterMainData, state.diamondReducer.access_token])

    useEffect(() => {
        const abc = new Promise(async (resolve) => {
            const res: any = await callWordpressAPI(env.RepNetAuthTokenUrl)
            dispatch(diamondReducerAction.ADD_API_TOKEN(res.access_token))
        })
        abc.then()

    }, [])

    return (<div className="shop-conflict-free">
        <div className="container">
            <Filters></Filters>
            <EarringListing/>
        </div>
    </div>);
}
