import React, {useEffect, useState} from 'react';
import "../pages/diamond-show.css";
import {Button, Col, Container, Image, Row, Table} from "react-bootstrap";
import {Link, useNavigate, useParams} from 'react-router-dom';
import env from '../env.json';
import axios from 'axios';
import {Modal} from "antd";
import CertiComponent from "../components/diamond-show/CertificateComponent";
import ContactComponent from "../components/diamond-show/ContactComponent";
import SocialComponent from "../components/common/SocialComponent";
import ExportColor from '../components/diamond-show/expert-color';
import CaratImage from '../components/diamond-show/carat-image';
import ExpertClarity from '../components/diamond-show/expert-clarity';
import ExpertCut from '../components/diamond-show/expert-cut';
import ExpertFluore from '../components/diamond-show/expert-fluore';
import LabComponent from '../components/diamond-show/LabComponent';
import {useDispatch, useSelector} from "react-redux";
import {diamondReducerAction} from "../redux/reducers/diamondReducer";
import {RootState} from "../redux/store";
import {callWordpressAPI} from "../utils/general";

const ProductImage = ""
const diamondColorImg = ""
const diamondcutImg = ""
const FluorescenceImg = ""

const assuranceImg = ""
const vdoImg = ""
const dimensionsImg = ""

function DiamondShow() {
    const apiTokenData = ""

    // get the param with hook of router dom
    const {firstDiamondID, secondDiamondID, productID, diamondID} = useParams<any>();
    const state = useSelector((state: RootState) => state);
    let navigate = useNavigate();

    // set the specific diamond object response
    const [diamond, setDiamond] = useState<any>([]);
    const [secondDiamond, setSecondDiamond] = useState<any>([]);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [currentModalComponent, setCurrentModalComponent] = useState<any>(null);
    const [modalTitle, setModalTitle] = useState<string>('');
    const dispatch = useDispatch();
    useEffect(() => {


        const firstOption = {
            method: 'POST',
            url: env.newRepNetSingleURL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': state.diamondReducer.access_token
            },
            data: JSON.stringify({
                request: {
                    header: {
                        // username: env.username,
                        // password: env.password,
                    },
                    body: {
                        diamond_id: parseInt(firstDiamondID as string)
                    },
                }
            }),
        };

        axios(firstOption).then((res) => {

            if (res.data.response.header.error_message !== "" && res.data.response.header.error_message === "No Diamond was found") {
            } else {
                setDiamond(res.data.response.body.diamond)
                // setFoundDiamonds(res.data.response.body.search_results.total_diamonds_found);
            }
        })
            .catch(error => {
            });
        const secondOption = {
            method: 'POST',
            url: env.newRepNetSingleURL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': state.diamondReducer.access_token
            },
            data: JSON.stringify({
                request: {
                    header: {
                        // username: env.username,
                        // password: env.password,
                    },
                    body: {
                        diamond_id: parseInt(secondDiamondID as string)
                    },
                }
            }),
        };

        axios(secondOption).then((res) => {

            if (res.data.response.header.error_message !== "" && res.data.response.header.error_message === "No Diamond was found") {
            } else {
                setSecondDiamond(res.data.response.body.diamond)
                // setFoundDiamonds(res.data.response.body.search_results.total_diamonds_found);
            }
        })
            .catch(error => {
            });
    }, [firstDiamondID,state.diamondReducer.access_token]);

    useEffect(() => {
        const abc = new Promise(async (resolve) => {
            const res: any = await callWordpressAPI(env.RepNetAuthTokenUrl)
            dispatch(diamondReducerAction.ADD_API_TOKEN(res.access_token))
        })
        abc.then()

    }, [])

    const arrColor = ['D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];
    const colorSec = diamond && arrColor.map((code, index) => (
        <div key={index} className="color-code text-center">
            <Image src={diamondColorImg}/>
            <p className={code === diamond.color ? 'active' : ''}>{code}</p>
        </div>
    ));

    const arrClarit = [
        {
            code: 'FL',
            name: 'Flawless'
        },
        {
            code: 'IF',
            name: 'Internally Flawless'
        },
        {
            code: 'VVS1',
            name: 'Very Very Slightly Included'
        },
        {
            code: 'VVS2',
            name: 'Very Very Slightly Included'
        },
        {
            code: 'VS1',
            name: 'Very Slightly Included'
        },
        {
            code: 'VS2',
            name: 'Very Slightly Included'
        },
        {
            code: 'SI1',
            name: 'Slightly Included'
        },
        {
            code: 'SI2',
            name: 'Slightly Included'
        },
        {
            code: 'I1',
            name: 'Included'
        },
        {
            code: 'I2',
            name: 'Included'
        }
    ];

    const claritySec = arrClarit.map((info, index) => (
        <div key={index}
             className={`Clarity-code text-center ${info.code === diamond.clarity ? "diamond-tooptip" : ""} `}>
            <p className={info.code === diamond.clarity ? 'active' : ''}>{info.code}</p>
            <p className="sub-text">{info.name}</p>
        </div>
    ));

    const arrCut = ['Excellent', 'Very Good', 'Good', 'Fair', 'Poor'];
    const cutSec = arrCut.map((cut, index) => (
        <Col className="col-lg-2 col-md-6" key={index}>
            <div className="color-code text-center">
                <Image src={diamondcutImg} className="d-cut-img"/>
                <p className={`cut-name ${cut === diamond.cut ? 'active' : ''} `}>{cut}</p>
            </div>
        </Col>
    ));

    const arrFluorescence = ['None', 'Very Slight', 'Medium', 'Strong', 'Very Strong'];
    const fluorescenceSec = arrFluorescence.map((fluorescence, index) => (
        <Col className="col-lg-2 col-md-6" key={index}>
            <div className="color-code text-center">
                <Image src={env.wp_media_path + FluorescenceImg} className="d-cut-img"/>
                <p className={fluorescence === diamond.fluor_intensity ? 'active' : ''}>{fluorescence}</p>
            </div>
        </Col>
    ));

    const handleAddcompare = () => {
        for (let i = 0; i < state.diamondReducer.comparisonEarring.length; i++) {
            if (state.diamondReducer.comparisonEarring[i].diamond_id == diamond.diamond_id) {
                return {};
            }
        }
        dispatch(diamondReducerAction.ADD_IN_COMPARISON(({one: diamond, two: secondDiamond})))

    }

    const handleShowModal = () => {
        // event.preventDefault();
        setIsModalOpen(true);
    };

    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    const renderModalContent = () => {
        switch (currentModalComponent) {
            case 'askAboutDiamond':
                return <ContactComponent/>
            case 'certificate':
                return <CertiComponent did={firstDiamondID}/>
            default:
                return null;
        }
    }

    const certiHtml = () => {
        if (diamond.lab === "HRD") {
            return <a href={`https://my.hrdantwerp.com/?id=34&record_number=${parseInt(diamond.cert_num)}`}
                      target="_blank">
                View Certificate
            </a>;
        } else {
            return <a href="#" onClick={e => {
                e.preventDefault();
                setCurrentModalComponent('certificate');
                setModalTitle('');
                handleShowModal();
            }}> View Certificate
            </a>;
        }
    }

    return (
        <Container className="products-container">
            <Row className="Product-main-row">
                <Col md={8}>
                    <div className="product-single-img">
                        <span className='pwidth'>WIDTH {diamond.meas_width}MM</span>
                        <Image src={env.wp_media_path + ProductImage} fluid/>
                        <span className='plength'>LENGTH {diamond.meas_length}MM</span>
                    </div>

                    <Row>
                        <Col md={2}>
                            <span>Dimensions</span>
                            <Image src={env.wp_media_path + dimensionsImg} thumbnail/>
                        </Col>
                        <Col md={2}>
                            <span></span>
                            <Image src={env.wp_media_path + vdoImg} thumbnail/>
                        </Col>
                        <Col md={2}>
                            <span>Certificate</span>
                            <LabComponent lab={diamond.lab}/>
                        </Col>
                        <Col md={2}>
                            <span>Sarvada Assurance</span>
                            <Image src={env.wp_media_path + assuranceImg} thumbnail/>
                        </Col>
                    </Row>
                </Col>
                <Col md={4}>
                    <p>Natural, Conflict-Free</p>
                    <p><Link to={`/${state.diamondReducer.journey}/${productID}`}>*<i className="fa fa-window-close"
                                                                                      aria-hidden="true"></i></Link></p>
                    <h2 className="product-title-single">
                        {diamond.size && `${diamond.size} Carat`} {diamond.shape && `${diamond.shape} Diamond`} {diamond.lab && `Graded By ${diamond.lab}`}
                    </h2>
                    <SocialComponent/>
                    <div className="">
                        {diamond.color && `${diamond.color} color`} | {diamond.clarity && `${diamond.clarity} Clarity`} | {diamond.cut && `${diamond.cut} Cut `}
                        {certiHtml()}
                        <Modal open={isModalOpen} title={modalTitle} centered onCancel={handleModalCancel} footer={[]}>
                            {renderModalContent()}
                        </Modal>
                    </div>
                    {diamond.total_sales_price_in_currency &&
                        <div className="price-single-pro">
                          <h4>Rs {diamond.total_sales_price_in_currency}</h4>
                          <span>(Rs {diamond.total_sales_price_in_currency})</span>
                        </div>
                    }
                    {productID != "independentDiamond" && productID != "noEarringProduct" ? (
                        <div className="final-diamond">
                            <a className="sec-bg btn btn-primary"
                               href={`${env.wphost}/customized-complete-product?diamondId=${firstDiamondID}`}>
                                Choose this Diamond
                            </a>
                        </div>) : false}
                    {productID == "independentDiamond" || productID == "noEarringProduct" ? (
                        <div className="final-diamond">
                            <a className="sec-bg btn btn-primary"
                               href={`${env.wphost}/product-category/solitaire-jewellery/create-your-earrings?firstDiamondId=${firstDiamondID}&secondDiamondId=${secondDiamondID}`}>
                                Add to Earring
                            </a>
                        </div>) : false}
                    {productID == "independentDiamond" ? (<div className="final-diamond">
                        <a className="sec-bg btn btn-primary"
                           href={`${env.wphost}/customized-complete-product?firstDiamondId=${firstDiamondID}&secondDiamondId=${secondDiamondID}`}>
                            Add to Cart
                        </a>
                    </div>) : false}
                    <Button className="ad-compare-btn" onClick={e => {
                        handleAddcompare();
                        navigate(`/${state.diamondReducer.journey}/${productID}`)
                    }}>Add to Compare</Button>
                    <p className="note-text">
                        Estimated Shipping Date Of Loose Diamond : 05 March 23 | <br/>
                        Want Earlier? <a href="#">Contact Us </a>
                    </p>
                    <p className="note-subtext">Varies when mounted into a setting</p>
                </Col>
            </Row>

            <Row className="product-details-single">
                <Col>
                    <h3 className="product-details-ext">Product Details</h3>
                    <hr/>
                    <p className="productshort-desc">
                        This {diamond.size ? diamond.size : ''} carat
                        | {diamond.clarity ? diamond.clarity : ''} natural {diamond.shape ? diamond.shape : ''} diamond
                        has {diamond.color ? diamond.color : ''} color, {diamond.clarity ? diamond.clarity : ''} clarity
                        and a diamond grading report from {diamond.lab ? diamond.lab : ''}
                    </p>
                    <p className="pro-tab-heading">Product Details</p>

                    <Table striped>
                        <tbody>
                        <tr>
                            <td>Sku</td>
                            <td>{diamond.stock_num ? diamond.stock_num : '-'} </td>
                            <td>Symmetry</td>
                            <td>{diamond.symmetry ? diamond.symmetry : '-'}</td>
                        </tr>
                        <tr>
                            <td>Shape</td>
                            <td>{diamond.shape ? diamond.shape : '-'}</td>
                            <td>Measurements</td>
                            <td>{diamond.meas_length}x{diamond.meas_width}x{diamond.meas_depth}</td>
                        </tr>
                        <tr>
                            <td>Certificate</td>
                            <td>{diamond.lab ? `${diamond.lab} ${diamond.cert_num}` : '-'} {diamond.lab ? certiHtml() : ''}</td>
                            <td>Table %</td>
                            <td>{diamond.table_percent ? `${diamond.table_percent}%` : '-'}</td>
                        </tr>
                        <tr>
                            <td>Carat</td>
                            <td>{diamond.size ? diamond.size : '-'}</td>
                            <td>Total Depth %</td>
                            <td>{diamond.depth_percent ? `${diamond.depth_percent}%` : '-'}</td>
                        </tr>
                        <tr>
                            <td>Color</td>
                            <td>{diamond.color ? diamond.color : '-'}</td>
                            <td>Lenght/Width Ratio</td>
                            <td>-</td>
                        </tr>

                        <tr>
                            <td>Clarity</td>
                            <td>{diamond.clarity ? diamond.clarity : '-'}</td>
                            <td>Girdle Thickness</td>
                            <td>-</td>
                        </tr>

                        <tr>
                            <td>Cut</td>
                            <td>{diamond.cut ? diamond.cut : '-'}</td>
                            <td>Culet</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>Polish</td>
                            <td>{diamond.polish ? diamond.polish : '-'}</td>
                            <td>Shade/Milky</td>
                            <td>-</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row className="diamond-details-single">
                <Col md={12}>
                    <div className="diamond-details-heading text-center">
                        <h3>The Four C’s and F of Your Diamond</h3>
                        <h4>Carat Weight: {diamond.size} Ct</h4>
                    </div>
                    <p className="text-center">
                        The weight of diamonds is measured in carat and it is often confused
                        with the size of the diamond. 1 carat equals 200 miligrams or 0.200
                        gms. However, to actually understand the physical size of a diamond,
                        we should look at it’s mm measurements. Higher carat weight is of
                        course desirable but it should be balanced out with the other
                        specifications.
                    </p>
                    <div className="blue-bg-box text-center side-padding">
                        <h6>Sarvada Experts:</h6>
                        <p> We recommend setting a range of desired quality specifications and then maximising the carat
                            weight within the budget while always selecting the best cut grade. <br/>
                            <a href="#">Learn More</a>
                        </p>
                    </div>
                    <div className="light-bg-box">
                        <Row>
                            <Col md={12}>
                                <CaratImage shape={diamond.shape}/>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className="diamond-details-single">
                <Col md={12}>
                    <div className="diamond-details-heading text-center">
                        <h4>Color : {diamond.color}</h4>
                    </div>
                    <p className="text-center">
                        The color evaluation of a diamond is based on the absence of color,
                        so when we mention color, we are actually talking about the
                        colorlessness of a diamond. Color grades range from D to Z color,
                        with D being the most colorless and the most expensive.
                    </p>
                    <div className="blue-bg-box text-center side-padding">
                        <h6>Sarvada Experts:</h6>
                        <div>
                            <ExportColor color={diamond.color}/>
                        </div>
                    </div>
                    <div className="color-img-box">
                        {colorSec}
                    </div>
                </Col>
            </Row>
            <Row className="diamond-details-single">
                <Col md={12}>
                    <div className="diamond-details-heading text-center">
                        <h4>Clarity : {diamond.clarity}</h4>
                    </div>
                    <p className="text-center">
                        Clarity refers to the presence of imperfections, flaws and blemishes
                        inside or on the surface of a diamond and it is usually graded as
                        viewed under 10X magnification. Clearer the diamond, the more
                        expensive it will be.
                    </p>
                    <div className="blue-bg-box text-center side-padding">
                        <h6>Sarvada Experts:</h6>
                        <ExpertClarity clarity={diamond.clarity}/>
                    </div>
                    <div className="color-img-box">
                        {claritySec}
                    </div>
                </Col>
            </Row>
            <Row className="diamond-details-single">
                <Col md={12}>
                    <div className="diamond-details-heading text-center">
                        <h4>Cut : {diamond.cut}</h4>
                        <span>Polish : {diamond.polish} | Symmetry : {diamond.symmetry} </span>
                    </div>
                    <p className="text-center">
                        Cut is the most important factor of the 4C’s that determines a
                        diamond’s shine and sparkle. The entire cut grade is made up of Cut,
                        Polish and Symmetry grades and a diamond expert looks at various
                        cutting parameters to judge the cut. Well cut diamonds allow for
                        maximum light reflection and appear sparkly and poorly cut diamonds
                        appear lifeless and dull, even if they are of the higher color and
                        clarity grades.
                    </p>
                    <p className="text-center">
                        Cut grade is mentioned by the lab only for round diamonds. For other
                        shapes, the cut is judged by cutting parameters such as Table %,
                        Total Depth%, Girdle Thickness and Length/Width ratio. You can read
                        more details here or get in touch with our experts for assistance.
                    </p>
                    <div className="blue-bg-box text-center side-padding">
                        <h6>Sarvada Experts:</h6>
                        <ExpertCut cut={diamond.cut}/>
                    </div>
                    <div className="cut-img-box">
                        <Row className="d-flex justify-content-center">
                            {cutSec}
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className="diamond-details-single">
                <Col md={12}>
                    <div className="diamond-details-heading text-center">
                        <h4>Fluorescence : {diamond.fluor_intensity}</h4>
                    </div>
                    <p className="text-center">
                        Fluorescence can be observed in a diamond when it is exposed to UV
                        light and low levels of fluorescence are generally not visible to
                        the naked eyes. Fluorescence Intensity specifies how much
                        fluorescence is present in a diamond and Fluorescence Color
                        specifies the color of fluorescence under UV light - this is usually
                        always blue but can be other colors at times and affect the visual
                        appearance accordingly.
                    </p>

                    <div className="blue-bg-box text-center side-padding">
                        <h6>Sarvada Experts:</h6>
                        <ExpertFluore fluore={diamond.fluor_intensity}/>
                    </div>
                    <div className="cut-img-box">
                        <Row className="d-flex justify-content-center">
                            {fluorescenceSec}
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default DiamondShow;
