import "./diamond-finder.css";
import Filters from "../components/filter/filters";
import EarringListing from '../components/listings/earringsListing'
import DiamondListing from '../components/listings/diamondListing'
import env from '../env.json';
import {useDispatch, useSelector} from "react-redux";
import {diamondReducerAction} from "../redux/reducers/diamondReducer";
import indFunction from '../components/common/IndFunctions'
import React, {useEffect} from "react";
import {useLocation, useSearchParams} from 'react-router-dom';
import {RootState} from "../redux/store";
import {callRepnetAPI, callWordpressAPI} from "../utils/general";

export default function DiamondFinder() {

    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();


    async function singleDiamondRefreshData() {
        if (state.diamondReducer.access_token && state.diamondReducer.access_token != "") {
            if (state.endUserFilter.search) {
                const data: any = await callRepnetAPI(env.newRepNetURL, state.diamondReducer.access_token, parseInt(state.endUserFilter.search))
                if (data) {
                    dispatch(diamondReducerAction.ADD_DIAMONDS_DATA({
                        diamonds: [data.response.body.diamond],
                        totalDiamonds: 1
                    }))
                }
            } else {
                const data: any = await callRepnetAPI(env.newRepNetURL, state.diamondReducer.access_token, indFunction.changeFilterToRepnet(state.endUserFilter, state.endUserFilter.filterMainData, state.diamondReducer.colorTab))
                if (data) {
                    if (data.search_results) {
                        dispatch(diamondReducerAction.ADD_DIAMONDS_DATA({
                            diamonds: data.diamonds,
                            totalDiamonds: data.search_results.total_diamonds_found,
                            returnedDiamonds: data.search_results.diamonds_returned
                        }))
                    } else {
                    }
                }
            }
        }
    }

    async function earringDiamondRefreshData() {
        if (state.diamondReducer.access_token && state.diamondReducer.access_token != "") {
            if (state.endUserFilter.search) {
                const data: any = await callRepnetAPI(env.newRepNetSingleURL, state.diamondReducer.access_token, parseInt(state.endUserFilter.search))
                if (data) {
                    let a = state.endUserFilter

                    a.shape = [data.response.body.diamond.shape];
                    a.fancy_color = [data.response.body.diamond.color];
                    a.clarity.min = data.response.body.diamond.clarity;
                    a.clarity.max = data.response.body.diamond.clarity;
                    a.cut.min = data.response.body.diamond.cut;
                    a.cut.max = data.response.body.diamond.cut;
                    a.polish = [data.response.body.diamond.polish];
                    a.symmetry = [data.response.body.diamond.symmetry];
                    a.labs = [data.response.body.diamond.lab];
                    a.fluorescence = [data.response.body.diamond.fluor_intensity];

                    if (data.response.body.diamond.fluor_intensity) a.fluorescence_intensities = data.response.body.diamond.fluor_intensity
                    const mainFilter = indFunction.changeFilterToRepnet(a, state.endUserFilter.filterMainData, state.diamondReducer.colorTab);
                    // console.log('checking earring now', data.response.body.diamond, mainFilter,a)
                    indFunction.findEarring(state.diamondReducer.access_token, [data.response.body.diamond], mainFilter, 1).then(finalEarring => {
                        dispatch(diamondReducerAction.ADD_DIAMONDS_DATA({diamonds: finalEarring, returnedDiamonds: 1}))
                    })
                }

            } else {
                const mainFilter = indFunction.changeFilterToRepnet(state.endUserFilter, state.endUserFilter.filterMainData, state.diamondReducer.colorTab)
                mainFilter.page_size = 60;
                const data: any = await callRepnetAPI(env.newRepNetURL, state.diamondReducer.access_token, mainFilter)
                if (data) {
                    if (data.search_results) {
                        indFunction.findEarring(state.diamondReducer.access_token, data.diamonds, mainFilter).then(finalEarring => {
                            dispatch(diamondReducerAction.ADD_DIAMONDS_DATA({
                                diamonds: finalEarring,
                                totalDiamonds: data.search_results.total_diamonds_found,
                                returnedDiamonds: data.search_results.diamonds_returned
                            }))
                        })

                    } else {
                    }
                }
            }
        }
    }

    useEffect(() => {
        let loc = location.pathname;
        loc = loc.substring(1, loc.length)
        loc = loc.substring(0, loc.indexOf('/'));
        dispatch(diamondReducerAction.ADDJOURNEY(loc))

        if (state.endUserFilter.earringSearch == false) singleDiamondRefreshData()
        if (state.endUserFilter.earringSearch) earringDiamondRefreshData()
    }, [state.endUserFilter, state.endUserFilter.filterMainData, state.diamondReducer.access_token])

    useEffect(() => {
        const abc = new Promise(async (resolve) => {
            const res: any = await callWordpressAPI(env.RepNetAuthTokenUrl)
            dispatch(diamondReducerAction.ADD_API_TOKEN(res.access_token))
        })
        abc.then()

    }, [])

    return <div className="shop-conflict-free container">

        <Filters></Filters>
        {state.endUserFilter.earringSearch == false ? <DiamondListing/> : null}
        {state.endUserFilter.earringSearch ? <EarringListing/> : null}

    </div>
        ;
}
